<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_home_06.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-start">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A região de Mariana, incluindo a sede, seus distritos e
                  subdistritos, na época de sua formação (período colonial)
                  possuía extensas áreas de mata cobrindo as montanhas, com
                  espécies de árvores cuja madeira apresenta enorme durabilidade
                  e resistência para suportar cargas, chamadas de madeira de
                  lei. Ali estava a matéria-prima bastante eficiente para compor
                  as estruturas das casas, pilares, vigas, madres, frechais,
                  além do engradamento (estrutura) do telhado, para execução das
                  tesouras, peças de cumeeira, terças, caibros e ripas. A
                  madeira também serviria para fabricar os enquadramentos de
                  vãos de portas e janelas, bem como as folhas de fechamento
                  desses vãos. Na fase de finalização das obras, as tábuas de
                  madeira cumpriam ainda a função de forro e piso.
                </p>
                <p>
                  Entre a vegetação local, era abundante a taquara e o bambu,
                  cuja fibra possibilitava a produção de painéis com trama
                  entrelaçada, bastante utilizados como forro. As montanhas da
                  região possuíam ainda grande quantidade e variedade de rochas
                  , com boa resistência para suportar peso, que seriam
                  utilizadas como fundação das construções, assim como para
                  erguer paredes, geralmente nos templos religiosos e
                  edificações maiores. A abundância de pedras possibilitou que
                  muros também fossem erguidos com elas, seja de modo empilhado
                  e travado (pedra seca) ou unindo os blocos com argamassa.
                </p>
                <p>
                  O próprio solo trazia potencial para ser usado como massa,
                  misturado com água, fibra vegetal, a depender do caso, também
                  com areia e cal. A massa de barro foi usada então para compor
                  os painéis de pau a pique, tijolos de adobe e fabricação de
                  telhas. A proteção das paredes também teve o solo como
                  componente, usado no chapisco e no reboco. Além da
                  possibilidade de ser usado como matéria-prima para a
                  construção de paredes, os diferentes tipos de solo permitiam a
                  produção de tintas, utilizadas no acabamento das casas.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_casas_33.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista da paisagem da comunidade de Pedras, com relevo montanhoso
            coberto por mata, afloramentos rochosos e moitas de bambu. Foto:
            Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_34.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa com fundação de pedras, construída em um terreno rochoso da
            comunidade de Campinas. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_35.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casarão em terreno rural de Paracatu de Baixo com estrutura autônoma
            de madeira. Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_36.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista da fachada de fundos da Casa com rótulas em Santa Rita Durão.
            Observa-se a parede de pau a pique e o muro de pedras. Foto: Acervo
            ETII/IPHAN/Mariana, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_37.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista de parede executada com tijolos de adobe em uma casa de
            Paracatu de Baixo. Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_38.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista interna de uma casa em Pedras, com estrutura de madeira roliça
            e em parte com forro de esteira. Foto: Vistoria técnica da Cáritas,
            2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_39.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Vista da fachada frontal de uma casa em Pedras, com esquadrias de
            madeira. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section
      class="container d-flex flex-column justify-space-between border__image house__mb mt-10"
      style="max-width: 30em; height: 20em;"
    >
      <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-center align-center">
          <div
            @click="navToPage('conteudos_tematicos_casas_telhado')"
            style="background-color: #EDBD43; z-index: 10; text-align: center; margin-bottom: 0; border-radius: 6px; cursor: pointer"
          >
            <p style="text-align: center; margin-bottom: 0; padding: 0.5em;">
              O Telhado
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-center align-center">
          <div
            @click="navToPage('conteudos_tematicos_casas_paredes')"
            style="background-color: #EDBD43; z-index: 10; text-align: center; margin-bottom: 0; border-radius: 6px; cursor: pointer"
          >
            <p style="text-align: center; margin-bottom: 0; padding: 0.5em;">
              Paredes
            </p>
          </div>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <div
            @click="navToPage('conteudos_tematicos_casas_pintura')"
            style="background-color: #EDBD43; z-index: 10; text-align: center; margin-bottom: 0; border-radius: 6px; cursor: pointer"
          >
            <p style="text-align: center; margin-bottom: 0; padding: 0.5em;">
              A Pintura
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-center align-center">
          <div
            @click="navToPage('conteudos_tematicos_casas_portas_janelas')"
            style="background-color: #EDBD43; z-index: 10; text-align: center; margin-bottom: 0; border-radius: 6px; cursor: pointer"
          >
            <p style="text-align: center; margin-bottom: 0; padding: 0.5em;">
              Portas e Janelas
            </p>
          </div>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <div
            @click="navToPage('conteudos_tematicos_casas_oficio_carpinteiro')"
            style="background-color: #EDBD43; z-index: 10; text-align: center; margin-bottom: 0; border-radius: 6px; cursor: pointer"
          >
            <p style="text-align: center; margin-bottom: 0; padding: 0.5em;">
              O Ofício de Carpinteiro
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-center align-center">
          <div
            @click="navToPage('conteudos_tematicos_casas_estrutura')"
            style="background-color: #EDBD43; z-index: 10; text-align: center; margin-bottom: 0; border-radius: 6px; cursor: pointer"
          >
            <p style="text-align: center; margin-bottom: 0; padding: 0.5em;">
              A Estrutura
            </p>
          </div>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <div
            @click="navToPage('conteudos_tematicos_casas_alicerce')"
            style="background-color: #EDBD43; z-index: 10; text-align: center; margin-bottom: 0; border-radius: 6px; cursor: pointer"
          >
            <p style="text-align: center; margin-bottom: 0; padding: 0.5em;">
              O Alicerce
            </p>
          </div>
        </v-col>

        <v-col class="d-flex justify-center align-center">
          <div
            @click="navToPage('conteudos_tematicos_casas_tecnica_preserva')"
            style="background-color: #EDBD43; z-index: 10; text-align: center; margin-bottom: 0; border-radius: 6px; cursor: pointer"
          >
            <p style="text-align: center; margin-bottom: 0; padding: 0.5em;">
              Técnica para Preservar
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casas /",
          href: "conteudos_tematicos_casas",
        },
        {
          text: "Técnicas tradicionais construtivas",
          href: "conteudos_tematicos_casas_tecnicas_construtivas",
        },
      ],
    };
  },

  methods: {
    navToPage(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.house__mb {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.border__image {
  width: 100%;
  height: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_ilustracao.png");
  background-size: 100% 100%;
}
</style>
